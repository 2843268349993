


.about {
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  height: 115vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease, background-image 0.5s ease, background-color 0.5s ease;
  overflow: hidden;
}

.parallax-bg-about {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0;
  transition: opacity 1s ease-out, background-image 1s ease-out;
}

.about[data-current-image='1'] .parallax-bg-about { 
  background-image: url('../images/about5.jpg');
  opacity: 1;
}

.about[data-current-image='2'] .parallax-bg-about {
  background-image: url('../images/about4.jpg');
  opacity: 1;
}

.about[data-current-image='3'] .parallax-bg-about {
  background-image: url('../images/about2.jpg');
  opacity: 1;
}

.about[data-current-image='4'] .parallax-bg-about {
  background-image: url('../images/about6.jpg');
  opacity: 1;
}

.about .content {
  width: 50%;
  border-radius: 10px; /* Optional: for rounded corners */
  padding: 20px;
  margin: 10px; /* Add margin for better spacing */
  margin-bottom: 350px;
  margin-right: 300px;
}

.about h2, .about p {
  color: #fff; /* White text color */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6); /* Text shadow for legibility */
  font-size: calc(1.2vw + 1rem);
}

.about h2 {
  font-size: calc(1.5vw + 0.8em); /* Responsive font size */
  text-align: left;
  margin-bottom: 20px;
}

.about p {
  font-size: calc(0.8vw + 0.3rem);
  line-height: 1.6;
  text-align: left;
}

/* Optional: Animate text appearance */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.about h2, .about p {
  animation: fadeIn 1s ease-out;
}

/* Portrait View */
@media screen and (max-width: 940px) {
  .about .arrow.left,
  .about .arrow.right {
    position: absolute;
    top: 700px; /* Adjust this value to move the buttons higher or lower */
    font-size: 18px; /* Decreased font size for mobile */
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    transform: translateY(-50%);
  }

  .about h2 {
    font-size: 1.4rem; /* Smaller font size for mobile */
    margin-top: 20px; /* Adjust as needed to prevent cut-off */
    line-height: 1.2; /* Adjust line height for better readability */
    margin-bottom: 20px;
    text-align: left; /* Right-align text */
  }

  .about p {
    font-size: 1rem; /* Smaller font size for mobile */
    line-height: 1.4; /* Adjust line height for better readability */
    text-align: left; /* Right-align text */
  }

  .about .content {
    width: 100%;
    margin-top: 100px; /* Adjust this value to move the text further up */
    padding: 20px; /* Adjust padding as needed for spacing */
    text-align: center; /* Right-align text */
    margin-left: 300px;
  }

}

/* Landscape View */
@media screen and (max-height: 600px) {

  .about {
    width: 100vw; /* Ensure full viewport width */
    height: 100vh; /* Ensure full viewport height */
    margin: 0; /* Remove any default margins */
    padding: 0; /* Remove any default paddings */
    overflow: hidden; /* Prevent scrollbars */
  }
  .about .arrow.left,
  .about .arrow.right {
    top: 60%; /* Adjust top position for landscape view */
    margin-top: 0; /* Reset margin-top for landscape */
  }

  .about h2 {
    font-size: 1.2rem; /* Slightly smaller font size for landscape */
  }

  .about p {
    font-size: 0.9rem; /* Slightly smaller font size for landscape */
  }

  .about .content {
    margin-top: 400px; /* Adjust the top margin for landscape */
    padding: 15px; /* Adjust padding for landscape */
  }

  .about .arrow.left {
    left: 10px;
    margin-top: 100px;
  }

  .about .arrow.right {
    right: 10px;
    margin-top: 100px;
  }
}