.header {
  position: fixed;
  height: 80px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  transition: .3s ease-in;
  overflow: hidden;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
  cursor: pointer;
}

.header .nav-menu a {
  color: #ffffff;
  text-shadow: -1px -1px 2px #000000;
}

.header .navbar img {
  width: 100px;
  height: auto;
  position: left;
  padding-top: 40px;
  padding-bottom: 10px;
}

.header .nav-menu {
  display: flex;
  padding-top: 50px;
}

.header .nav-item {
  padding: 1rem;
  font-weight: 500;
}

.header .nav-item a {
  position: relative;
  color: #ffffff;
  text-shadow: -1px -1px 2px #000000;
  transition: color 0.3s, border-bottom 0.3s;
}

.header .nav-item a:hover {
  border-bottom: 3px solid var(--secondary-color);
}


.hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  .hamburger {
    display: block;
    position: fixed;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 1000;
  }

  .header .navbar {
    max-width: 100%;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 0;
    flex-direction: column;
    background-color: #000;
    width: 100%;
    height: 100%; /* Make the navbar take up the whole screen */
    z-index: 999;
    text-align: center;
    transition: .3s;
    padding-top: 80px; /* Adjust based on the height of your header */
    overflow-y: scroll; /* Enables scrolling within the navbar */
  }

  .nav-menu.active {
    left: 0;
    -webkit-overflow-scrolling: touch;
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .header .navbar img {
    width: 100px;
    padding-bottom: 50px;
  }

  .nav-menu a {
    color: #ffffff;
    text-shadow: -1px -1px 2px #000000;
  }

  /* This will prevent scrolling on the body when the nav-menu is active */
  body.no-scroll {
    overflow: hidden;
    height: 100vh; /* Viewport height */
    position: fixed; /* Prevents background scrolling */
    width: 100%; /* Prevents width reflow */
    touch-action: none; /* Prevents touch scrolling on mobile devices */
  }
}