* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.hero {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.left-half, .right-half {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    z-index: -1;
}

.left-half {
    left: 0;
    background-image: url('../images/home1.jpg');
}

.right-half {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100vh;
    background-image: url('../images/home2.jpg') !important;
    background-size: cover;
    background-position: center;
    z-index: -1;
}

  .hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1100px;
    padding: 1rem;
    padding-left: 352px; /* Add additional left padding for specific styling */
}

/* Styling for the first paragraph within the hero content */
.hero p:first-child {
    font-style: italic;
    /* Set the font style to italic */
    font-size: 1rem;
    /* Set the font size to 1rem */
}

/* Styling for the main heading within the hero content */
.hero h1 {
    font-size: 3rem;
    /* Set the font size to 3rem */
    font-weight: 700;
    /* Set the font weight to 700 for boldness */
    margin: 1rem 0;
    /* Set the top and bottom margins of the heading */
}

/* Styling for the third paragraph within the hero content */
.hero p:nth-child(3) {
    font-size: 2rem;
    /* Set the font size to 2rem */
    padding-bottom: 10px;
    /* Add padding to the bottom for spacing */
}

/* Styling for the fourth paragraph within the hero content */
.hero p:nth-child(4) {
    font-size: 1.2rem;
    /* Set the font size to 1.2rem */
    font-style: italic;
    /* Set the font style to italic */
}

/* Styling for the button within the hero content */
.hero .button {
    margin: 1.5rem 0;
    /* Set the top and bottom margins of the button */
    width: 250px;
    /* Set the width of the button */
    background: linear-gradient(to right, #ff4500, #ff0000);
    /* Set the background color using a CSS variable */
    border: 1px solid var(--secondary-color);
    /* Set the border color using a CSS variable */
    color: #ffffff;
    /* Set the text color to white */
    text-align: center;
}

/* Styling for the button hover state within the hero content */
.hero .button:hover {
    background-color: var(--secondary-dark);
    /* Set the background color on hover using a CSS variable */
    border: 1px solid var(--secondary-dark);
    /* Set the border color on hover using a CSS variable */
}

/* Media query for responsive styling on screens with a maximum width of 940px */
@media screen and (max-width: 940px) {
    .hero .content {
        padding-left: 1rem;
        /* Add some padding to the left for better spacing on smaller screens */
        align-items: flex-start;
        /* Align items to the start of the container horizontally */
    }

    .hero .button {
        width: 100%;
        /* Set the width of the button to 100% for smaller screens */
        margin-top: 2rem;
        /* Add space between the text and the button for smaller screens */
        margin-right: 0;
        /* Remove right margin for left alignment on smaller screens */
        padding-left: 0;
        /* Remove left padding for left alignment on smaller screens */
        text-align: center;
    }

    /* Add this CSS to remove horizontal scrolling on mobile */
    body {
        overflow-x: hidden;
    }

    body {
        overflow-x: hidden;
    }

    .left-half,
    .right-half {
        width: 50%; /* Set the width to 100% for mobile view */
    }
}
