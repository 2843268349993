/* Footer.css */

.footer {
  background-color: black !important;
  color: #bbbbbb;
  padding: 40px 0;
  cursor: pointer;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.columns {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.column {
  flex: 1;
  text-align: left;
  padding: 0 15px;
}

.column:not(:last-child) {
  border-right: 1px solid #ffffff;
  padding-right: 15px;
}

h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #b32f2f;
}

.logo {
  max-width: 150px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.column ul li {
  font-size: 16px;
  margin-bottom: 10px;
}

.column ul li a {
  text-decoration: none;
  color: #dadada;
  transition: color 0.3s ease;
}

.column ul li a:hover {
  color: #b32f2f;
}

.social-icons-footer {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
  align-items: center; /* Add this to align icons vertically */
  width: 100%; /* Ensure full width */
  position: relative;
}

.social-icons-footer a {
  color: #bbbbbb; /* Change color as needed */
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons-footer a:hover {
  color: #b32f2f;
}

/* Bottom */
.bottom {
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
}


.line {
  width: 80px;
  height: 2px;
  background-color: #b32f2f;
  margin: 10px auto;
}


.column p {
  font-size: 14px;
  margin: 0;
  color: #bbbbbb;
}

.bottom p {
  font-size: 14px;
  margin: 0;
  color: #bbbbbb;
}

@media only screen and (max-width: 768px) {
  .columns {
    flex-direction: column;
    align-items: center;
  }

  .column {
    text-align: center;
    padding: 0;
  }

  .column:not(:last-child) {
    border-right: none;
    padding-right: 0;
  }

  .column:first-child {
    padding-left: 0;
  }

  .logo {
    max-width: 120px;
  }

  ul {
    gap: 10px;
  }

  .column ul li {
    font-size: 14px;
    margin-bottom: 8px;
    text-align: center;
  }

  
}
