.icon {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.listings-header {
    text-align: center;
    margin-bottom: 40px;
    position: relative;
}

.listings-header h1 {
    font-size: 3rem;
    font-weight: bold;
    background: #ffffff;
    -webkit-background-clip: text;
    /* For WebKit browsers */
    background-clip: text;
    /* Standard syntax */
    color: transparent;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 8px;
}

.listings-header p {
    font-size: 1.2rem;
    color: #e7e7e7;
    font-family: 'Poppins', sans-serif;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.5;
}

.listings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
    background: linear-gradient(60deg, #111, #222, #333, #444, #333, #222, #111);
    background-size: 200% 100%;
    animation: gradientShift 8s ease-in-out infinite;
    margin-bottom: 20px;
}

@keyframes gradientShift {

    0%,
    100% {
        background-position: 100% 50%;
    }

    50% {
        background-position: 0 50%;
    }
}


.vehicle-listing {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px;
    border: none;
    max-width: 876px;
    max-height: 533px;
    cursor: pointer;
    border-radius: 8px;
    opacity: 0;
    transform: translateY(30px);
    transition: transform 0.3s ease, opacity 0.3s ease;
    background: #eeeeee;
    margin-bottom: 10px;
    /* Add margin at the bottom to lower the divider line between vehicle listings */
    position: relative;
}

/* 
.vehicle-listing::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px; 
    width: 100%;
    height: 2px; 
    background-color: #e0dbdb; 
    transition: all 0.3s ease;
    animation: breatheLine 1s infinite;
    animation-timing-function: ease-in-out;
}

@keyframes breatheLine {
    0%, 100% {
        width: 0;
        left: 50%;
    }
    50% {
        width: 100%; 
        left: 0; 
    }
} */

.image-container {
    max-width: 50%;
    overflow: hidden;
}

.image-container img {
    width: 95%;
    height: auto;
    border: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.details-container {
    flex: 1;
    padding: 0 20px;
    border-left: 1px solid #000000;
    font-family: 'Open Sans', sans-serif;
    /* Default font for details */
}

.top-details,
.bottom-details {
    text-align: left;
}

.price,
.mileage,
.transmission-type,
.fuel-type,
.calendar {
    font-size: 1rem;
    margin-bottom: 8px;
    color: #000;
    /* Corrected the color code */
}

.price {
    font-size: 1.8rem;
    font-weight: 700;
    /* Montserrat bold */
    color: var(--secondary-color);
    /* Ensure this variable is defined in your root or use a hex color */
    font-family: 'Montserrat', sans-serif;
    /* Specific font for price */
}


body {
    font-family: 'Open Sans', sans-serif;
    /* Set the base font for the body */
    overflow-y: auto;
}

.modal-open {
    overflow: hidden;
    /* Disable vertical scrolling when modal is open */
}

.name {
    font-size: 1.6rem;
    font-weight: 600;
    color: #000000;
}

.used-status {
    color: #7c7c7c;
    font-size: 1rem;
    margin-bottom: 8px;
}

.vehicle-listing .mileage,
.vehicle-listing .transmission-type,
.vehicle-listing .fuel-type,
.vehicle-listing .calendar {
    font-size: 1rem;
    margin-bottom: 8px;
    color: #000000;

}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    overflow-y: auto;
}

.modal-content {
    display: flex;
    flex-direction: column;
    max-width: 63%;
    width: auto;
    max-height: 90vh;
    overflow: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.image-carousel-container {
    max-height: 100px;
    max-width: 100px;
    overflow: hidden;
    display: flex;
}

.image-carousel {
    position: relative;
    display: flex;
    flex-direction: column;
}

.carousel-image {
    width: 100%;
    height: 80%;
    max-height: 100vh;
    object-fit: contain;
    flex-shrink: 0;
}

.details-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.carousel-button {
    position: absolute;
    top: 300px;
    font-size: 24px;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
    transform: translateY(-50%);
}

.carousel-button.prev {
    left: 10px;
    margin-top: 100px;
}

.carousel-button.next {
    right: 10px;
    margin-top: 100px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
}

.image-counter {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #fff;
}

.car-details-container {
    display: none;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;
    overflow-y: hidden;
    z-index: 2;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

}

.image-details-divider {
    width: 1px;
    background-color: #ccc;
    margin: 0 20px;
}

.image-carousel.show-details .car-details-container {
    display: flex;
}

.styled-details {
    font-family: 'Your Chosen Font', sans-serif;
    font-size: 16px;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f7f7f7;
}

.section-title {
    color: #333;
    font-size: 24px;
    border-bottom: 2px solid #666;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.section-content {
    color: #555;
}

.details-info p {
    margin: 5px 0;
}

.extras-list {
    display: flex;
    flex-direction: column;
}

.extra-item {
    margin: 5px 0;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.enquiry-form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.enquiry-form {
    max-width: 300px;
    width: 100%;
    box-sizing: border-box;
}

.enquiry-form h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.enquiry-form input,
.enquiry-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 36px;
    background: #fff;
    color: #333;
}

.enquiry-form textarea {
    height: 100px;
}

.enquiry-form label {
    margin-bottom: 8px;
    color: #030303;
}

.enquire-button {
    width: 100%;
    /* Fill the available width */
    height: 70px;
    /* Set the desired height */
    padding: 20px;
    /* Adjust the padding to increase height */
    background-color: #b32f2f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    box-sizing: border-box;
}

.enquiry-form button {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

.enquiry-form button:hover {
    background-color: #555555;
}

.banner-container {
    position: relative;
    background-color: var(--secondary-color);
    /* A shade of red */
    height: 60px;
    width: 80%;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.7);
    /* Add a subtle shadow */
    margin-bottom: 52px;
    margin-top: 30px;
}

.enquiry-form label {
    margin-bottom: 8px;
    color: #030303;
    position: relative;
}

.required-field {
    font-size: 0.8rem;
    color: #ff0000;
    /* Red color */
    margin-left: 3px;
}

.enquiry-form button.submit-button {
    background-color: #b32f2f;
    /* Change the color to your desired color */
    color: #fff;
    /* Set text color to white or a contrasting color */
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

/* Add a hover effect if desired */
.enquiry-form button.submit-button:hover {
    background-color: #802323;
    /* Change the hover color to your desired color */
}

.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.arrow-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #fff;
    /* Set arrow color to white */
    transition: transform 0.2s ease-in-out;
}

.arrow-button:hover {
    transform: scale(1.2);
    /* Slightly increase size on hover */
}

.page-indicator {
    font-size: 16px;
    color: #fff;
    /* Set text color to white */
    margin: 0 auto;
    font-weight: bold;
    /* Make the page count bold */
}

.banner-text {
    text-align: center;
    flex-grow: 1;
}

/* Add animation for the banner */
@keyframes fancyBanner {
    0% {
        background-color: #ff4d4d;
    }

    50% {
        background-color: #ff6666;
    }

    100% {
        background-color: #ff4d4d;
    }
}

.banner-container.scrolled {
    position: fixed;
    top: 0;
    animation: fancyBanner 1s infinite;
    /* Add animation on hover */
}


@media (max-width: 768px) {


    .image-container {
        max-width: 100%;
        max-height: 60vh;
        overflow: hidden;
    }

    .image-container img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .vehicle-listing {
        flex-direction: column;
        /* Change flex direction to column for mobile view */
        align-items: center;
        /* Center items in the column */
        max-width: 100%;
        /* Full width for mobile view */
    }

    .details-container {
        padding: 10px;
        border-left: none;
        text-align: left;
        /* Align text to the left */
        max-width: 100%;
        /* Full width for mobile view */
    }

    .modal-content {
        max-width: 90%;
        max-height: 90%;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        position: relative;
        z-index: 1001;
    }

    .image-carousel-container {
        max-height: 200vh;
        overflow: hidden;
    }

    .image-carousel img {
        max-width: 100%;
        max-height: 50vh;
        object-fit: contain;
    }

    .enquiry-form-container {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        /* Stack items vertically */
        justify-content: flex-end;
        /* Align items to the bottom */
        align-items: center;
    }

    .enquire-button {
        width: 100%;
        height: 40px;
        /* Set your desired height */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .enquiry-form {
        max-width: 100%;
    }

    .show-enquiry-form .enquiry-form-container {
        display: flex;
    }

    .carousel-button {
        position: absolute;
        top: 130px;
        /* Adjust this value to move the buttons higher or lower */
        font-size: 24px;
        color: #fff;
        background: none;
        border: none;
        cursor: pointer;
        transform: translateY(-50%);
    }

    .carousel-button.prev {
        left: 1px;
        margin-top: 11%;
        /* Adjust this value to move the "prev" button to the left */
    }

    .carousel-button.next {
        right: 1px;
        margin-top: 11%;
        /* Adjust this value to move the "next" button to the right */
    }

}

@media only screen and (max-width: 768px) and (orientation: landscape) {

    .modal-content {
        max-width: 90%;
        max-height: 90%;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        position: relative;
        z-index: 1001;
    }

    .image-carousel-container {
        max-height: 200vh;
        overflow: hidden;
    }

    .image-carousel img {
        max-width: 100%;
        max-height: 100vh;
        object-fit: contain;
    }

    .vehicle-listing {
        display: flex; /* Use flexbox to layout children */
        flex-direction: row; /* Arrange children in a row */
        justify-content: center; /* Center children horizontally */
        align-items: center; /* Center children vertically */
        padding: 10px; /* Add some padding */
        gap: 10px; /* Add gap between image and details */
    }

    .image-container {
        width: 50%; /* Allocate width for the image container */
        height: auto; /* Set height to auto */
    }

    .image-container img {
        width: 100%; /* Image should take up the full width of its container */
        height: auto; /* Image height should be auto to maintain aspect ratio */
        object-fit: contain; /* Image should be contained within its element */
    }

    .details-container {
        width: 50%; /* Allocate width for the details container */
        padding: 10px; /* Add padding inside the details container */
    }

    .modal-content .image-container {
        width: 70%; /* Increase the width of the image container */
        height: auto; /* Optional: Adjust the height as necessary */
        margin: 0 auto; /* Center the image container */
    }

    .modal-content .image-container img {
        width: 100%; /* Image should take up the full width of its container */
        height: auto; /* Adjust the height to maintain aspect ratio */
        object-fit: contain; /* Ensure the entire image is visible */
    }
}
