.featured {
  position: relative;
  background: linear-gradient(to right, #0e0e0e 0%, #414141 50%, #0e0e0e 100%);
  color: #fff;
  height: 110vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: 'Helvetica Neue', sans-serif;
  /* Modern and clean font */
}

.carousel-container-featured {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  /* Adjusted margin for better spacing */
  position: relative;
}

.carousel-container-featured img {
  width: 100%;
  max-width: 400px;
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 15px;
  /* Slightly rounded edges for a modern look */
  margin-right: 30px;
  /* Increased margin for a spacious layout */
  transition: transform 0.5s ease, opacity 0.5s ease;
  /* Smooth transition */
  opacity: 0.5;
  /* Subtle opacity for an elegant look */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  /* Soft shadow for depth */
  margin-top: 160px;
}

.carousel-container-featured img.middle-image {
  opacity: 1;
  transform: scale(1.2);
  /* More pronounced scaling */
}

.carousel-container-featured img:hover {
  opacity: 1;
  transform: scale(1.25);
  z-index: 1;
  /* Higher z-index for the hovered image */
}

.featured-text {
  padding: 30px;
  border-radius: 8px;
  margin-top: 200px;
  background: rgba(0, 0, 0, 0.8);
  /* Semi-transparent black background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  text-align: center;
  /* Center-aligned text */
}

.featured-text h1 {
  font-size: 40px;
  /* Larger font size for prominence */
  color: #d4af37;
  /* Gold color for an expensive feel */
  margin-bottom: 10px;
  /* Added bottom margin for spacing */
}

.featured-text p {
  font-size: 20px;
  /* Larger font size for readability */
  color: #e6e6e6;
  /* Light grey for subtle contrast */
  max-width: 600px;
  /* Max width for better text alignment */
  margin: auto;
  /* Center the paragraph */
}

.mobile-slider {
  display: none;
}


@media (max-width: 768px) {
  .carousel-container-featured img {
    display: none; /* Hide desktop images on mobile */
  }

  .mobile-slider {
    display: block; /* Show mobile slider */
  }

  .mobile-slider .slick-slide img {
    display: block;
    width: 100%; /* Adjust as needed */
    max-width: 400px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center images */
  }
  .slick-slider, .slick-list, .slick-track {
    position: relative;
  }
  
  .slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slick-slide img {
    max-width: 100%;
    height: auto; /* Adjust height as necessary */
    display: block; /* This ensures the image is not inline */
  }
}