.contact {
    background: url('../images/contact.jpg') no-repeat center center/cover;
    color: #fff;
    height: 110vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align text to the left */
    justify-content: center;
    position: relative;
    padding: 20px;
    overflow: hidden;
    margin-top: -20px;
}

.contact::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.text-container {
    text-align: center;
    margin-left: 87px;
    /* Align text to the left */
}

.text-container h1 {
    margin-bottom: 10px;
}

.contact-text {
    padding-left: 0;
}

.content-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* Align items to the start */
    width: 100%;
}

.form-container {
    width: 60%;
    padding: 0 20px;
    margin-right: 40px;
    margin-left: 70px;
    /* Adjust the right margin as needed */
}

form {
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 12px;
    margin-top: 20px;
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-wrapper {
    width: 100%;
    margin-top: 20px;
}

.map-margin-container {
    margin-top: 170px; /* Adjust this value to move the map down */
}

.map-container {
    width: 40%;
    height: 500px;
    border-radius: 12px;
    padding-bottom: 100px;
}

.map-container iframe {
    width: 100%;
    /* or adjust the percentage accordingly */
    height: 100%;
    border-radius: 12px;
}


label {
    display: block;
    margin-bottom: 8px;
    color: #333;
}

input,
textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 6px;
}

button {
    background-color: #ff4d4d;
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #cc0000;
}

.social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-icons a {
    color: #fff;
    margin: 0 10px;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #d9534f;
}

/* Additional styles for the contact info column */
.contact-info {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    position: relative;
}

.contact-info:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at bottom, #ff0000 0%, #000 70%);
    border-radius: 8px;
    /* Adjust the border-radius to match the main container */
    z-index: -1;
}


@media (max-width: 768px) {
    .contact {
        align-items: center;
        text-align: left;
        padding: 20px;
        padding-left: 20px;
        height: auto;
        /* Set height to auto for mobile */
    }

    .contact h1 {
        text-align: center;
    }

    .contact .text-container {
        width: 100%;
        padding-right: 85px; /* Adjust this value to push the text 50px to the left */
    }

    .text-container h1 {
        display: inline-block; /* Display "Contact Us" and "Have questions or feedback?" on separate lines */
        margin-bottom: 10px; /* Add space between the two lines */
    }

    .content-container {
        flex-direction: column;
    }

    .form-container,
    .map-container {
        width: 100%;
        /* Full width for mobile view */
        margin-left: 0;
    }

    .form-container {
        width: 100%;
    }

    .map-margin-container {
        margin-top: 20px; /* Adjust this value for mobile */
    }

    .map-container {
        margin-bottom: 20px;
        padding-bottom: 0;
        height: 300px;
        /* Adjust the height as needed for your design */
    }

    .map-container iframe {
        width: 100%;
        height: 100%;
        /* Ensure the iframe takes full height within the container */
        border-radius: 12px;
        /* Optionally apply border-radius to the map */
    }

    form {
        max-width: 100%;
        padding: 15px;
        box-sizing: border-box;
        z-index: 0;
    }

    label,
    input,
    textarea {
        width: 100%;
        /* Make the input fields full-width */
        box-sizing: border-box;
    }
}